<template>
  <div>
    <div class="jumbotron-wrapper image">
      <div class="jumbotron container-lg px-4">
        <div class="animation background cover-text ps-0"  style="margin-top: 50px">
          <h1>交易终端 FTSTP</h1>
          <p>采用业界领先的 Rust 技术，构建高效安全的算法交易系统</p>
        </div>
      </div>
    </div>
    <div class="content p-auto" style="margin-top:50px">
      <section id="Platform" >
      <section>
        <div class="container-lg px-4 py-5">
          <div class="row align-items-center g-5 py-8">
            <div class="col-lg-6">
              <!--h1 class="display-5 fw-bold lh-1 mb-3 te">非凸智能交易系统</h1-->
              <h2>界面展示</h2>
              <p class="lead mt-5">
                将高并发低延迟设计落地于交易系统的每个环节，从海量数据模型训练到回测分析，从行情采集到实时信号预测，再到报单指令执行，均采用领先业界的技术实现。
              </p>
              <a
                role="button"
                class="btn btn-outline-primary me-3"
                target="_blank"
                href="/非凸智能交易系统产品使用手册.pdf"
                >下载手册</a
              >
              <a
                role="button"
                class="btn btn-outline-primary"
                target="_blank"
                href="https://www.bilibili.com/video/BV1um421j771/?spm_id_from=333.999.0.0&vd_source=48940ef7f313a1bdf0a465665bca355a"
                >视频指南</a
              >
            </div>
            <div class="col-lg-6">
              <swiper class="swiper" :options="swiperOption">
                <swiper-slide>
                  <img
                    src="../../assets/img/products/manual-01.jpg"
                    alt="非凸科技"
                    class="slide"
                  />
                </swiper-slide>
                <swiper-slide class="slide">
                  <img
                    src="../../assets/img/products/manual-02.jpg"
                    alt="非凸科技"
                    class="slide"
                  />
                </swiper-slide>
                <swiper-slide class="slide">
                  <img
                    src="../../assets/img/products/manual-03.jpg"
                    alt="非凸科技"
                    class="slide"
                  />
                </swiper-slide>
                <swiper-slide class="slide">
                  <img
                    src="../../assets/img/products/manual-04.jpg"
                    alt="非凸科技"
                    class="slide"
                  />
                </swiper-slide>
                <swiper-slide class="slide">
                  <img
                    src="../../assets/img/products/manual-05.jpg"
                    alt="非凸科技"
                    class="slide"
                  />
                </swiper-slide>
                <!-- <div class="swiper-pagination">
                  <slot name="pagination"></slot>
                </div>
                <div class="swiper-button-prev">
                  <slot name="button-prev"></slot>
                </div>
                <div class="swiper-button-next">
                  <slot name="button-next"></slot>
                </div> -->
              </swiper>
            </div>
          </div>
        </div>
      </section>
      </section> 
      <section>
        <div class="py-5 bg-light">
          <div class="container-lg px-4">
            <div class="row mt-5 mb-5">
              <div class="col-md-12 ml-auto">
                <h2>设计目标</h2>
              </div>
            </div>
            <div class="row mt-5">
              <div class="col-md-6">
                <div class="product_media">
                  <div class="mx-3">
                    <img src="../../assets/img/products/客户端/高性能.png"/>
                  </div>
                  <div class="media-body">
                    <h3 class="h4">高性能</h3>
                    <p class="text-dark text-left">
                      超大数据集离线训练与实时回测<br />
                      上万种标的物行情预测及实时报撤单
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="product_media">
                  <div class="mx-3">
                    <img src="../../assets/img/products/客户端/低延迟.png"/>
                  </div>
                  <div class="media-body">
                    <h3 class="h4">低延迟</h3>
                    <p class="text-dark text-left">
                      计算通信延迟均在纳秒级优化<br />
                      充分考虑CPU Cache优化细节
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="product_media">
                  <div class="mx-3">
                    <img src="../../assets/img/products/客户端/高可靠.png"/>
                  </div>
                  <div class="media-body">
                    <h3 class="h4">高可靠</h3>
                    <p class="text-dark text-left">
                      线上免维护<br />
                      杜绝崩溃等非正常退出
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="product_media">
                  <div class="mx-3">
                    <img src="../../assets/img/products/客户端/开发效率高.png"/>
                  </div>
                  <div class="media-body">
                    <h3 class="h4">开发效率高</h3>
                    <p class="text-dark text-left">
                      有较好的生态和基础库，无须造轮子<br />
                      因子&模型&策略在/离线使用同一份代码<br />
                      无须关注操作系统及硬件差异
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div class="py-5">
          <div class="container-lg px-4">
            <div class="row mt-5">
              <div class="col-md-12 ml-auto">
                <h2>产品优势</h2>
              </div>
            </div>
            <div class="row mt-5">
              <div class="col-sm-4">
                <div class="product_media">
                  <div class="mx-3">
                    <img src="../../assets/img/products/客户端/前沿技术.png"/>
                  </div>
                  <div class="media-body">
                    <h3 class="h4">前沿技术</h3>
                    <p class="text-dark text-left">
                      基于 Rust 生态构建高效安全的算法交易系统，运行效果稳定，绩效表现优秀<br />
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="product_media">
                  <div class="mx-3">
                    <img src="../../assets/img/products/客户端/领先算法.png"/>
                  </div>
                  <div class="media-body">
                    <h3 class="h4">领先算法</h3>
                    <p class="text-dark text-left">
                      精准量价预测，多重策略并发，深度机器学习，持续迭代更新，算法容量富余<br />
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="product_media">
                  <div class="mx-3">
                    <img src="../../assets/img/products/客户端/策略团队.png"/>
                  </div>
                  <div class="media-body">
                    <h3 class="h4">策略团队</h3>
                    <p class="text-dark text-left">
                      配备强大的策略开发服务团队，提供策略开发辅助、培训工作，以及个性化定制服务<br />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div class="py-5 bg-light">
          <div class="container-lg px-4">
            <div class="row mt-5">
              <div class="col-md-12 ml-auto">
                <h2>产品功能</h2>
              </div>
            </div>
            <div class="row mt-5">
              <div class="col-sm-4">
                <div class="product_media">
                  <div class="mx-3">
                    <img src="../../assets/img/products/客户端/多账户管理.png"/>
                  </div>
                  <div class="media-body">
                    <h3 class="h4">多账户管理</h3>
                    <p class="text-dark text-left">
                      一个客户端同时管理多个券商不同交易账号，提升使用及管理效率<br />
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="product_media">
                  <div class="mx-3">
                    <img src="../../assets/img/products/客户端/算法交易.png"/>
                  </div>
                  <div class="media-body">
                    <h3 class="h4">算法交易</h3>
                    <p class="text-dark text-left">
                      搭载非凸所有算法，包括拆单、T0、被动算法等，支持股票、转债及ETF；统一监控，实时把握交易进度<br />
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="product_media">
                  <div class="mx-3">
                    <img src="../../assets/img/products/客户端/接入方式.png"/>
                  </div>
                  <div class="media-body">
                    <h3 class="h4">接入方式</h3>
                    <p class="text-dark text-left">
                      新增多种下单方式，提供文件单、扫单、API 、手动提交等多种接入方式<br />
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="product_media">
                  <div class="mx-3">
                    <img src="../../assets/img/products/客户端/绩效展示.png"/>
                  </div>
                  <div class="media-body">
                    <h3 class="h4">绩效分析</h3>
                    <p class="text-dark text-left">
                      基础的绩效结算和绩效展示功能，利用图表等可视化工具直观浏览，支持定制统计和展示字段<br />
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="product_media">
                  <div class="mx-3">
                    <img src="../../assets/img/products/客户端/分仓管理.png"/>
                  </div>
                  <div class="media-body">
                    <h3 class="h4">分仓管理</h3>
                    <p class="text-dark text-left">
                      将选定的资金账户证券数量分仓到选定的子账户中，在每个账户中采用独立的交易策略和风险管理措施<br />
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="product_media">
                  <div class="mx-3">
                    <img src="../../assets/img/products/客户端/增强交易.png"/>
                  </div>
                  <div class="media-body">
                    <h3 class="h4">增强交易</h3>
                    <p class="text-dark text-left">
                      支持客户自主编程扩展，进而满足投资者个性化交易需求<br />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div class="py-5">
          <div class="container-lg px-4">
            <div class="row mt-5 mb-5">
              <div class="col-md-12 ml-auto">
                <h2>产品价值</h2>
              </div>
            </div>
            <div class="row mt-5">
              <div class="col-md-6">
                <div class="product_media">
                  <div class="mx-3">
                    <img src="../../assets/img/products/客户端/量化私募.png"/>
                  </div>
                  <div class="media-body">
                    <h3 class="h4">量化私募</h3>
                    <p class="text-dark text-left">
                      轻松完成上千支股票调仓，大幅提高交易效率，降低冲击成本，获得超额收益
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="product_media">
                  <div class="mx-3">
                    <img src="../../assets/img/products/客户端/券商.png"/>
                  </div>
                  <div class="media-body">
                    <h3 class="h4">券商</h3>
                    <p class="text-dark text-left">
                      丰富算法交易平台产品线，吸引新客户、新资产，并为客户提供优质算法服务，提高客户黏性
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="customization">
        <div class="py-5 bg-light">
          <div class="container-lg px-4">
            <div class="row mt-5 mb-5 ps-1">
              <div class="col-md-12 ml-auto">
                <h2>定制服务</h2>
              </div>
            </div>
            <div class="overflow-auto">
              <div class="d-flex justify-content-around timeline-wrapper">
                <div
                  class="
                    w-100
                    d-flex
                    flex-column
                    justify-content-between
                    text-center
                  "
                >
                  <div
                    class="
                      customization-number
                      text-muted text-opacity-25
                      fw-bold
                    "
                  >
                    01
                  </div>
                  <div class="customization-line position-relative">
                    <div class="outer-circle bg-light">
                      <div class="inner-circle"></div>
                    </div>
                    <hr />
                  </div>
                  <div class="customization-content">
                    <b class="fs-4">需求分析</b>
                    <div>同客户沟通及确定产品需求，制作需求文档</div>
                  </div>
                </div>
                <div
                  class="
                    w-100
                    d-flex
                    flex-column
                    justify-content-between
                    text-center
                  "
                >
                  <div class="customization-content">
                    <b class="fs-4">设计</b>
                    <div>针对不同类型客户的不同需求，进行原型设计、接口设计</div>
                  </div>
                  <div class="customization-line position-relative">
                    <div class="outer-circle bg-light">
                      <div class="inner-circle"></div>
                    </div>
                    <hr />
                  </div>
                  <div class="customization-number text-muted fw-bold">02</div>
                </div>
                <div
                  class="
                    w-100
                    d-flex
                    flex-column
                    justify-content-between
                    text-center
                  "
                >
                  <div class="customization-number text-muted fw-bold">03</div>
                  <div class="customization-line position-relative">
                    <div class="outer-circle bg-light">
                      <div class="inner-circle"></div>
                    </div>
                    <hr />
                  </div>
                  <div class="customization-content">
                    <b class="fs-4">开发</b>
                    <div>敏捷开发，快速迭代，同客户时刻保持沟通</div>
                  </div>
                </div>
                <div
                  class="
                    w-100
                    d-flex
                    flex-column
                    justify-content-between
                    text-center
                  "
                >
                  <div class="customization-content">
                    <b class="fs-4">测试与部署</b>
                    <div>确定客户交易通道，接入测试，提供报告，完成部署</div>
                  </div>
                  <div class="customization-line position-relative">
                    <div class="outer-circle bg-light">
                      <div class="inner-circle"></div>
                    </div>
                    <hr />
                  </div>
                  <div class="customization-number text-muted fw-bold">04</div>
                </div>
                <div
                  class="
                    w-100
                    d-flex
                    flex-column
                    justify-content-between
                    text-center
                  "
                >
                  <div class="customization-number text-muted fw-bold">05</div>
                  <div class="customization-line position-relative">
                    <div class="outer-circle bg-light">
                      <div class="inner-circle"></div>
                    </div>
                    <hr />
                  </div>
                  <div class="customization-content">
                    <b class="fs-4">售后保障</b>
                    <div>持续关注客户使用情况，及时受理并解决新问题或新需求</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
export default {
  name: "Platform",
  components: {
    Swiper,
    SwiperSlide,
  },
  mounted() {
    this.$nextTick(() => {
      if (window.location.hash != "") {
        let anchor = this.$el.querySelector(window.location.hash);
        document.documentElement.scrollTop = anchor.offsetTop;
      }
    });
  },
  data() {
    return {
      swiperOption: {
        slidesPerView: 1,
        spaceBetween: 30,
        loop: true,
        allowTouchMove: false,
        autoplay: {
          delay: 3000,
          disableOnInteraction: true,
        },
      },
    };
  },
};
</script>

<style scoped lang="scss">
.customization-number {
  font-size: 3.75rem;
  flex: 1;
  opacity: 0.25;
  padding-top: 1.25rem;
}
.customization-content {
  flex: 1;
  padding-top: 1.25rem;
}
.outer-circle {
  height: 30px;
  width: 30px;

  border: 1px solid blue;
  border-radius: 50%;
  position: absolute;
  left: calc(50% - 15px);
  top: calc(50% - 15px);
  z-index: 1;
}
.inner-circle {
  background-color: blue;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  top: calc(50% - 10px);
  left: calc(50% - 10px);
  position: absolute;
}
.timeline-wrapper {
  height: 300px;
  min-width: map-get($container-max-widths, lg);
}
.slide {
  width: 100%;
}
.jumbotron-wrapper.image {
  position: relative;
  top: 50px;
  background-image: url("~@/assets/img/products/客户端.png");
  background-position-y: 15px;
}
.cover-text {
  color: unset;
  text-shadow: none;
}
</style>
